'use strict';

/**
 * @function
 * @description Initializes the sizeguide-content and layout
 */
exports.init = function () {
	// add bg styles to entire table col
	$('#size-guide-wrap').on('mouseover', "[class^='fit-column-']", function () {
		var num_id = $(this).attr('class').match(/fit-column-(\d+)/)[1];
		$(this).addClass('size-cell-hover');
		$(this).closest('table').find('.fit-column-' + num_id).addClass('fit-col-hover');
	});

	// remove bg styles to entire table col
	$('#size-guide-wrap').on('mouseout', "[class^='fit-column-']", function () {
		var num_id = $(this).attr('class').match(/fit-column-(\d+)/)[1];
		$(this).removeClass('size-cell-hover');
		$(this).closest('table').find('.fit-column-' + num_id).removeClass('fit-col-hover');
	});

	// toggle sizechart units
	$('#size-guide-wrap').on('click', '.btn-in, .btn-cm', function () {
		var unit_id = $(this).attr('class');
		if ($(this).attr('class') == 'btn-cm') {
			$('#size-guide-wrap').addClass('units-cm');
		} else {
			$('#size-guide-wrap').removeClass('units-cm');
		}
	});

	// load sizechart URL into off-canvas AJAX
	$(document).on('click', '.size-chart-lnk a', function (e) {
		e.preventDefault();
		$('#size-guide-wrap, #size-bg').addClass('show');
		$('html').addClass('show-size');
		var page = $(this).attr('href');
		$.ajax({
			url: page,
			dataType: 'html',
			beforeSend: function () {
                $('#size-guide-wrap .size-chart').prepend('<div class="loading" />');
			},
			success: function (data) {
				$('#size-guide-wrap .size-chart').html($(data));
				$('#size-guide-wrap .size-chart').prepend('<div id="close-size" />');
				$('#size-guide-wrap .loading').hide();
			}
		});
	});

	// close sizechart
	$(document).on('click touchstart', '#close-size, #size-bg', function () {
		$('#size-guide-wrap, #size-bg').removeClass('show');
		$('html').removeClass('show-size');
	});
};
